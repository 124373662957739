import axios from 'axios'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

const CancelToken = axios.CancelToken
let cancel

export function getEdudexImportPrograms(
    importId,
    page,
    rowsPerPage,
    filter,
    cancelPreviousRequest
) {
    if (cancelPreviousRequest && cancel !== undefined) {
        cancel()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.VUE_APP_PROVIDER_API_URL}${API_CALL_URL_PATHS.edudexImports}/${importId}/programs?offset=${page}&limit=${rowsPerPage}&filter=${filter}`,
            cancelToken: new CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                cancel = c
            })
        })
        .then((response) => {
                resolve(response.data)
            })
            .catch((response) => {
                reject(response)
            })
    })
}

export default { getEdudexImportPrograms }

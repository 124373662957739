<template>
    <div class="col-12"> 
        <recess-table-flex 
            class="qa-table-edudex-import-failures-list"
            :table-options="setTableOptions"
            :pagination-options="setPaginationOptions"
            @getData="updateList"
        > 

            <recess-table-flex-body>
                <recess-table-flex-row
                    v-for="program in importFailures"
                    :key="'importProgram-' + program.id"
                >
                    <recess-table-flex-cell>
                        <span class="u-color-error qa-edudex-program-url">{{
                            program && program.programUrl
                        }}</span>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell>
                        <ul>
                            <li
                                v-for="(error, index) in program.errors"
                                :key="'programErrors-' + index"
                                :class="`qa-edudex-program-error-${index}`"
                            >
                                {{ error && error.error }}
                            </li>
                        </ul>
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body>
     
        </recess-table-flex>
    </div>
</template>

<script>
import { getEdudexImportPrograms } from '@/api/EdudexClient'
import { PAGINATION } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json' 

import tableMixin from '@/../../shared/mixins/tableMixin'   

export default {
    name: 'EdudexImportFailuresList',
    mixins: [tableMixin],
    props: {
        setSelectedFilters: {
            type: Object,
            default:() => {
                return {}
            }
        }
    },
    data() {
        let paginationOptions = [
            { displayText: '5', value: 5 },
            { displayText: '10', value: 10 },
            { displayText: '25', value: 25 },
            { displayText: '50', value: 50 },
            { displayText: '100', value: 100 }, 
            { displayText: '250', value: 250 }
        ]

        return {
            PAGINATION,
            PROVIDER,
            importFailures: [], 
            filter: {
                search: '',
                top: 0,
                skip: 0,
                orderBy: [],
                filter: null
            }, 

            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                title: PROVIDER.Portfolio.EdudexFeed.ImportList.TableTitle,
                setCellSizes: true 
            },
            setPaginationOptions: { 
                showTopPagination: true, 
                showBottomPagination: true, 
                currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
                totalCount: null,
                selectOptions: paginationOptions,
                defaultTotalItemsPerPage: paginationOptions[2].value,
                itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[2].value,
                showSelectOption: true, 
                selectOptionsLabel: PAGINATION.selectOptionsLabel,
                paginationShowLabel: PAGINATION.paginationShowLabel,
                paginationOfLabel: PAGINATION.paginationOfLabel 
            } 
        }
    }, 
    methods: {
        updateList({currentPage, itemsPerPage}) { 
            this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
            this.setPaginationOptions.currentPage = currentPage

            this.filter.filter = 'statusId eq 30'
            this.filter.top = itemsPerPage
            this.filter.skip = currentPage * itemsPerPage - itemsPerPage
            this.filter.currentPage = currentPage    
 

            // Get data from api
            this.getDataOnPageChange()
        },

        getDataOnPageChange() { 
            this.getEdudexImportProgramsList()
            this.setCurrentPageAsQueryParam()
        },
        async getEdudexImportProgramsList() {
            try {
                const response = await getEdudexImportPrograms(
                    this.$route.params.id,
                    this.filter.skip,
                    this.filter.top,
                    this.filter.filter,
                    false
                )
                if (!response || !response.items) return
                this.importFailures = response.items 
                this.setPaginationOptions.totalCount = response.metadata.pagination.total
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving edudex import errors list',
                    error
                )
            }
        }
    }
}
</script>
